import React, { useState } from "react"
import styled from "styled-components"
import SecondaryTitle from "../common/SecondaryTitle"
import Chevron from "../../assets/svgs/chevron.svg"

const StyledChevron = styled(Chevron)`
  transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(-90deg)")};
  height: 32px;
  width: 18px;
  min-width: 30px;
  margin-left: auto;
  transition: transform 0.3s ease;

  @media (max-width: 40em) {
    width: 16px;
    min-width: 27px;
  }
`

const HowWeOperateSection = styled.section`
  background: #f8f8f8;
  margin-top: 7.5rem;
  padding-top: 4.5rem;
  padding-bottom: 5.125rem;

  @media (max-width: 40em) {
    margin-top: 5.5rem;
    padding-top: 2rem;
    padding-bottom: 5.125rem;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 2.5rem);
  width: 800px;
  margin-inline: auto;
`

const ItemContainer = styled.div`
  margin-bottom: 2rem;

  @media (min-width: 40em) {
    margin-left: 3rem;
  }
`
//cursor: ${props => props.cursor || "auto"};
const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5.5rem;
  cursor: ${props => (props.cursor ? "pointer" : "auto")};
  justify-content: ${props => (props.center ? "center" : "start")};

  @media (max-width: 40em) {
    gap: 2rem;
  }
`

const Number = styled.div`
  font-family: var(--ff-secondary);
  font-size: 2.625rem;
  font-weight: 800;
  color: var(--clr-primary);
  width: 30px;

  line-height: 50px;

  @media (max-width: 40em) {
    font-size: 2.5rem;
    text-align: top;
  }
`

const Paragraph = styled.div`
  color: #000;
  font-family: var(--ff-primary);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  z-index: 1;
  display: flex;
  max-width: 580px;
  width: 580px;

  @media (max-width: 40em) {
    font-size: 0.875rem;
  }
`

const ExbandableContainer = styled.div`
  margin-top: 2rem;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`

const ExpandableContent = styled.div`
  border-radius: 10px;
  background: inherit;
  text-align: left;

  color: inherit;
  background: ${props => props.background || "white"};
  font-family: var(--ff-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  margin-top: 1rem;
  max-width: 100%;
  width: 580px;
  margin-inline: auto;

  box-shadow: rgb(122, 121, 121, 0.2) 0px 0px 0px 1px;

  padding: 16px;

  @media (max-width: 40em) {
    font-size: 0.875rem;
  }
`

const ListComponent = ({ title, items, expandableItems, center = false }) => {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleItem = index => {
    setOpenIndex(openIndex === index ? null : index)
  }
  return (
    <HowWeOperateSection>
      <Container>
        <SecondaryTitle>{title}</SecondaryTitle>
        {items.map((item, index) => (
          <ItemContainer key={index}>
            <Item
              onClick={() => toggleItem(index)}
              cursor={expandableItems && expandableItems[index]}
              center={center}
            >
              <Number>{index + 1}</Number>
              <Paragraph className="text">{item.map(item => item)}</Paragraph>
              {expandableItems && expandableItems[index] && (
                <StyledChevron isOpen={openIndex === index} />
              )}
            </Item>
            {expandableItems && expandableItems[index] && (
              <ExbandableContainer isOpen={openIndex === index}>
                {expandableItems[index].map(item => (
                  <ExpandableContent key={index}>{item}</ExpandableContent>
                ))}
              </ExbandableContainer>
            )}
          </ItemContainer>
        ))}
      </Container>
    </HowWeOperateSection>
  )
}

export default ListComponent
