import React from "react"
import styled from "styled-components"
import CareerNavigationBar from "./CareerNavigationBar"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import SalaryTable from "./SalaryTable"
import SalaryCounter from "./SalaryCounter"

const BenefitsSectionContainer = styled.section`
  position: relative;
`

const BenefitsCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;

  z-index: 0;
  position: relative;

  @media (min-width: 40em) {
    gap: 10rem;
    margin-top: 5.5rem;
    margin-bottom: 7.5rem;
  }
`

const BenefitSection = styled.div`
  gap: 2rem;
  width: 1090px;
  max-width: calc(100% - 2.5rem);
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: ${props => (props.reverse ? "column-reverse" : "column")};
  @media (min-width: 40em) {
    gap: 3rem;
    flex-direction: row;
  }
`

const BenefitSectionSalary = styled(BenefitSection)`
  gap: 2rem;
  width: 1090px;
  max-width: calc(100% - 2.5rem);
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: ${props => (props.reverse ? "column-reverse" : "column")};
  padding-bottom: 1.25rem;
  @media (min-width: 40em) {
    gap: 3rem;
    flex-direction: row;
    padding-bottom: 2rem;
    scroll-margin-top: 0rem;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media (min-width: 40em) {
    max-width: 450px;
    gap: 2rem;
  }
  @media (min-width: 64em) {
  }
`

const TextContentSalary = styled(TextContent)`
  width: 100%;
  @media (min-width: 40em) {
    max-width: none;
  }
`

const Title = styled.h3`
  display: none;
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 1.5em;
  line-height: 39px;
  color: var(--clr-text-blue);

  @media (min-width: 40em) {
    display: block;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 39px;
    font-size: 2.125em;
    line-height: 48px;
    letter-spacing: 0.0025em;
  }
`

const TitleMobile = styled(Title)`
  display: block;
  @media (min-width: 40em) {
    display: none;
  }
`

const TitleSalary = styled(Title)`
  display: block;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 40em) {
    margin-bottom: 0;
  }
`

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 0.875em;
  line-height: 21px;
  letter-spacing: 0.0015em;
  max-width: 85ch;
  margin-inline: auto;

  @media (min-width: 40em) {
    font-size: 1em;
    line-height: 24px;
  }
`

const ImageContainer = styled.div`
  @media (min-width: 40em) {
    min-width: 350px;
    max-width: 520px;
  }
`

const Strong = styled.strong`
  font-weight: 700;
`

const SeeMoreButton = styled.a`
  text-decoration: none;
  background: var(--clr-primary);
  color: white;
  font-family: var(--ff-seondary);
  font-weight: 700;
  font-size: 1em;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.0046em;
  text-transform: uppercase;
  padding: 0.625rem 1.875rem;
  width: fit-content;

  margin-top: 2rem;
  margin-inline: auto;

  transition: 0.2s ease-in-out;
  &:hover {
    background-color: var(--clr-tertiary-bright);
    color: var(--clr-text-blue);
  }

  @media (min-width: 40em) {
    margin-top: 0;
    margin-inline: unset;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--clr-text-blue);

  &:hover {
    text-decoration: underline;
  }
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  color: var(--clr-text-blue);

  &:hover {
    text-decoration: underline;
  }
`

const BenefitsSection = () => {
  return (
    <BenefitsSectionContainer>
      <CareerNavigationBar></CareerNavigationBar>
      <BenefitsCards id="benefitsCards">
        <BenefitSectionSalary id="salary" reverse={true}>
          <TextContentSalary>
            <TitleSalary>Top Salary</TitleSalary>
            <ParagraphContainer>
              <Paragraph>
                Our salary model is simple. Your gross salary is{" "}
                <Strong>50%</Strong> of whatever you bill, and Rebase pays
                employer-side costs. Historically average salaries have been
                around <Strong>7000 - 8500 €/month</Strong>, with utilisation
                rates above 99%. Our top priority is making sure that everyone
                has a billable project. If you do ever find yourself waiting for
                work, we cover you with a <Strong>5000 € base salary</Strong> in
                your first six months, and 3000 € after that.
              </Paragraph>
              <Paragraph>
                Are you interested Are you interested in specific salary
                information? Check out the{" "}
                <StyledLink to="/open-positions">open positions</StyledLink>,
                where the average salaries for each role are listed.
              </Paragraph>
              <SalaryCounter />
              <SalaryTable
                thHeader="Utilisation"
                caption="Gross salary example (21 working days, 37,5 hours/week)."
              />
            </ParagraphContainer>
          </TextContentSalary>
        </BenefitSectionSalary>
        <BenefitSection id="ecosystem">
          <TitleMobile>Ecosystem</TitleMobile>
          <ImageContainer>
            <StaticImage
              src="../../images/career/section_01.jpg"
              alt=""
              quality={100}
            />
          </ImageContainer>
          <TextContent>
            <Title>Ecosystem</Title>
            <ParagraphContainer>
              <Paragraph>
                The Gofore Group ecosystem provides a steady flow of diverse
                projects for our experts. Our participation in the group is how
                we can offer everyone long-lasting (6-12 month) work
                opportunities and reliably full utilisation at attractive rates.
                Gofore also provides critical business functions like HR,
                finance, legal, and project sales.
              </Paragraph>
              <Paragraph>
                Beyond the Gofore ecosystem, Rebase also collaborates with
                well-known partners like Siili, Vincit, eWork, Solita, HiQ,
                Software Finland and{" "}
                <StyledExternalLink
                  href="https://www.itewiki.fi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ite wiki
                </StyledExternalLink>
                .
              </Paragraph>
            </ParagraphContainer>
            <SeeMoreButton
              href="https://gofore.com/en/cases/"
              title="Projects"
              target="_blank"
              rel="noopener noreferrer"
            >
              See example projects
            </SeeMoreButton>
          </TextContent>
        </BenefitSection>
        <BenefitSection id="flexibility" reverse={true}>
          <TextContent>
            <Title>Flexibility</Title>
            <ParagraphContainer>
              <Paragraph>
                We don’t define how much you work. You choose how much of your
                time you allocate—from part time to full time or more. Salary
                adjusts based on how much you work. Try out our{" "}
                <StyledLink to="/careers#salary">
                  potential earnings calculator
                </StyledLink>{" "}
                to see see some examples. With Rebase you’re never required to
                take on any you’re not interested in. We set you up with a
                project pool that match your skills and preferences, and you
                have total freedom to choose.
              </Paragraph>
              <Paragraph>
                {" "}
                Beyond that, we’re proudly a remote-first company, so for us, it
                doesn’t matter where you work. The only exceptions are when a
                chosen client project requires you to be on site with them, but
                this is something you’ll be aware of in advance of signing on.
              </Paragraph>
            </ParagraphContainer>
          </TextContent>
          <ImageContainer>
            <StaticImage src="../../images/career/section_02.jpg" alt="" />
          </ImageContainer>
          <TitleMobile>Flexibility</TitleMobile>
        </BenefitSection>

        <BenefitSection id="care-and-assurance">
          <TitleMobile>Care & Assurance</TitleMobile>
          <ImageContainer>
            <StaticImage src="../../images/career/section_04.jpg" alt="" />
          </ImageContainer>
          <TextContent>
            <Title>Care & Assurance</Title>
            <ParagraphContainer>
              <Paragraph>
                The benefits you get with Rebase go far beyond those required by
                law. We base our benefits package on what’s desirable and
                competitive in the IT services sector. You’ll get paid sick and
                family leave, reimbursed travel expenses, a holiday bonus and
                much more. You also get premium healthcare.
              </Paragraph>
              <Paragraph>
                As an employer, you can rest easy that Rebase is financially
                sound and secure, as wee proudly boast the maximum AA+/AAA
                credit rating. So you can focus on your work and know that it’s
                not going anywhere.
              </Paragraph>
            </ParagraphContainer>
          </TextContent>
        </BenefitSection>

        <BenefitSection id="community-and-partnership" reverse={true}>
          <TextContent>
            <Title>Community & Partnership</Title>
            <ParagraphContainer>
              <Paragraph>
                Founded in 2020, we're still fresh and new, so our experts have
                real input and a chance to help mold the company. On the other
                hand, you may focus on your customer projects and balance your
                work and personal life as you wish.
              </Paragraph>
              <Paragraph>
                We believe in fairness. Everyone committed to the company's
                success should also be allowed to be a partner. This means we
                offer all our people an opportunity to become company
                shareholders.
              </Paragraph>
            </ParagraphContainer>
          </TextContent>
          <ImageContainer>
            <StaticImage src="../../images/career/section_03.jpg" alt="" />
          </ImageContainer>
          <TitleMobile>Community & Partnership</TitleMobile>
        </BenefitSection>

        <BenefitSection id="flat-by-default">
          <TitleMobile>Flat by default</TitleMobile>
          <ImageContainer>
            <StaticImage src="../../images/career/section_05.jpg" alt="" />
          </ImageContainer>
          <TextContent>
            <Title>Flat by default</Title>
            <ParagraphContainer>
              <Paragraph>
                We don’t have career ladders, middle management, senior
                privileges or revolving negotiations around salary or job level.
                As a professional group of experts working for ourselves, we are
                all equal. You run your projects and earn in accordance with the
                rates you set and the hours you work. That’s all there is to it.
              </Paragraph>
            </ParagraphContainer>
          </TextContent>
        </BenefitSection>
      </BenefitsCards>
    </BenefitsSectionContainer>
  )
}

export default BenefitsSection
