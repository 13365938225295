import React from "react"
import styled from "styled-components"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

const tableContents = {
  priceHourly: [80, 100, 120, 140],
  allocationPercentage: [70, 80, 90, 100],
}

const StyledScrollBar = styled(SimpleBar)`
  .simplebar-scrollbar::before {
    background-color: rgba(0 0 0 0.6);
    margin-top: 0;
  }
  .simplebar-track {
    box-shadow: inset 0.884615px 0px 0px #dedede;
    background: #f9f9f9;
    margin-top: 6px;
    height: 7px !important;
  }
`

const Table = styled.table`
  width: 100%;
  min-width: 390px;
  max-width: 900px;
  margin-inline: auto;
  border-collapse: collapse;
  z-index: 2;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0.9375rem;
  margin-bottom: 12px;
  table-layout: fixed;
  font-family: var(--ff-primary);

  @media (min-width: 40em) {
  }
  @media (min-width: 64em) {
    margin-top: 1rem;
  }
`

const StyledThead = styled.thead`
  height: 32px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16px;
  color: var(--clr-text-blue);
  border-bottom: 4px solid #e00038;

  @media (min-width: 40em) {
    font-family: var(--ff-primary);
    font-size: 1.75rem;
    line-height: 28px;
    height: 80px;
    border-bottom: 5px solid #e00038;
    letter-spacing: 0.0025em;
  }
`

const StyledTh = styled.th`
  text-align: center;
`

const StyledTbody = styled.tbody`
  width: 100%;
`

const StyledTr = styled.tr``

const StyledTd = styled.td`
  overflow: hidden;
  position: relative;
  text-align: center;
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 16px;
  height: 32px;

  &:first-child {
    font-style: normal;
    font-weight: 700;
  }

  @media (min-width: 40em) {
    font-family: var(--ff-primary);
    line-height: 23px;
    font-size: 1.75rem;
    line-height: 28px;
    height: 112px;
  }
`

const TableCaption = styled.div`
  text-align: center;
  font-size: 0.5rem;
  font-weight: 400;
  position: relative;
  bottom: 0.5rem;
  margin-top: 0.8125rem;

  @media (min-width: 40em) {
    margin-top: 0.625rem;
    font-size: 0.75rem;
  }
`

const SalaryTable = ({ thHeader, caption }) => {
  const getSalary = (hourlyPrice, allocationPercentage) => {
    return Math.round(hourlyPrice * 0.5 * 157.5 * (allocationPercentage / 100))
  }

  return (
    <>
      <StyledScrollBar autoHide={false}>
        <Table>
          <StyledThead>
            <StyledTr>
              <StyledTh>{thHeader}</StyledTh>
              {tableContents?.priceHourly?.map((price, index) => {
                return (
                  <StyledTh key={`th-row-${index + 1}`}>{price} €/h</StyledTh>
                )
              })}
            </StyledTr>
          </StyledThead>
          <StyledTbody>
            {tableContents?.allocationPercentage?.map(
              (allocation, index, array) => {
                const isLast = index === array.length
                return (
                  <StyledTr
                    key={`tr-row-${index + 1}`}
                    className={isLast ? "border-bottom" : undefined}
                  >
                    <StyledTd>{allocation}%</StyledTd>
                    {tableContents?.priceHourly?.map((priceHourly, index) => {
                      return (
                        <StyledTd key={`td-el-${index + 1}`}>
                          {getSalary(priceHourly, allocation)} €
                        </StyledTd>
                      )
                    })}
                  </StyledTr>
                )
              }
            )}
          </StyledTbody>
        </Table>
      </StyledScrollBar>
      <TableCaption>{caption}</TableCaption>
    </>
  )
}

export default SalaryTable
