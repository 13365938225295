import React from "react"
import ListComponent from "../common/ListComponent"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledListComponent = styled(ListComponent)``

const StyledLink = styled(Link)`
  color: var(--clr-secondary-dark);
  font-weight: 600;
  display: contents;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
  font-size: 1.125em;
`

const OurRecruitmentProcess = () => {
  const items = [
    [
      <StyledLink key={1} to="#apply">
        Apply now!
      </StyledLink>,
      "  1st discussion – let’s engage over a virtual chat. ",
    ],
    ["Time to create your Rebase consultant CV."],
    ["We’ll start looking for your first project."],
    [
      "Competence interview – delving deeper into your ",
      "skills and expertise.",
    ],
    ["Customer interview."],
    [
      "Employment contract when the project is confirmed, ",
      "and your Rebase onboarding can start!",
    ],
  ]

  const expandableItems = undefined

  return (
    <StyledListComponent
      title="Our Recruiment Process"
      items={items}
      expandableItems={expandableItems}
      center={true}
    />
  )
}

export default OurRecruitmentProcess
