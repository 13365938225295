import React from "react"
import { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { debounce } from "../../utils/debounce"

const NavigationBarContainer = styled.div`
  z-index: 1;
  background: white;
  position: sticky;
  top: 74px;
  display: flex;
  margin-inline: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1140px) {
    margin-left: 0;
    justify-content: center;
  }

  @media (min-width: 40em) {
    top: 70px;
    padding-top: 1rem;
  }
`

const NavigationBar = styled.nav`
  display: flex;
  justify-content: center;
`

const MenuItem = styled.div`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  color: var(--clr-text-blue);
  justify-content: center;
  align-items: center;

  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 20px;

  text-align: center;

  min-width: 131px;
  min-height: 50px;
  width: 131px;
  height: 50px;
  border-bottom: 4px solid var(--clr-primary);
  @media (min-width: 40em) {
    font-size: 1.5em;
    line-height: 32px;

    width: 190px;
    height: 90px;
    min-width: 190px;
    min-height: 90px;
    border-width: 8px;
  }

  &:hover {
    color: var(--clr-secondary-dark);
  }
`

const CareerNavigationBar = () => {
  const [navTopPosition, setNavTopPosition] = useState(0)
  const [cardOffsets, setcardOffsets] = useState([])
  const containerRef = useRef()

  const scrollToPosition = index => {
    if (containerRef.current) {
      const menuItem = containerRef.current.children[0]?.children[index]
      if (menuItem) {
        const menuItemRect = menuItem.getBoundingClientRect()
        const scrollX = containerRef.current.scrollLeft + menuItemRect.left

        containerRef.current.scrollTo({
          top: 0,
          left:
            scrollX -
            containerRef.current.clientWidth / 2 +
            menuItemRect.width / 2,
          behavior: "smooth",
        })
      }
    }
  }

  const handleClick = (elementId, index) => {
    const targetElement = document.getElementById(elementId)

    if (targetElement) {
      let scrollMargin
      if (window.innerWidth > 640) {
        scrollMargin = 320
      } else {
        scrollMargin = 176
      }

      const targetElementRect = targetElement.getBoundingClientRect()
      const scrollX = window.pageXOffset + targetElementRect.left
      const scrollY = window.pageYOffset + targetElementRect.top - scrollMargin

      window.scrollTo({
        top: scrollY,
        left: scrollX,
        behavior: "smooth",
      })
      scrollToPosition(index)
    }
  }

  useEffect(() => {
    const cards = document.getElementById("benefitsCards").children
    if (cards) {
      const newCardOffsets = []
      for (let card of cards) {
        newCardOffsets.push(card.offsetTop)
      }
      setcardOffsets(newCardOffsets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]) //very much neccesary

  useEffect(() => {
    const getCurrentIndex = currentScrollPosition => {
      let offSet
      if (window.innerWidth > 640) {
        offSet = 74
      } else {
        offSet = 160
      }
      for (let i = 0; i < cardOffsets.length; i++) {
        if (
          currentScrollPosition >= cardOffsets[i] - offSet &&
          (currentScrollPosition < cardOffsets[i + 1] - offSet ||
            i === cardOffsets.length - 1)
        ) {
          return i
        }
      }
    }

    const scrollHandler = () => {
      const container = containerRef.current
      if (container) {
        setNavTopPosition(container.offsetTop)
        const currentIndex = getCurrentIndex(container.offsetTop)
        if (currentIndex !== undefined) {
          scrollToPosition(currentIndex)
        }
      }
    }

    const handleScroll = debounce(scrollHandler, 50)
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [cardOffsets])

  const activeStyle = index => {
    if (
      navTopPosition >= cardOffsets[index] - 160 &&
      (navTopPosition < cardOffsets[index + 1] - 160 ||
        index === cardOffsets.length - 1)
    ) {
      return {
        borderColor: "var(--clr-secondary-dark)",
        color: "var(--clr-secondary-dark)",
      }
    } else {
      return {}
    }
  }
  return (
    <NavigationBarContainer ref={containerRef}>
      <NavigationBar>
        <MenuItem
          onClick={() => handleClick("salary", 0)}
          style={activeStyle(0)}
        >
          Top salary
        </MenuItem>
        <MenuItem
          onClick={() => handleClick("ecosystem", 1)}
          style={activeStyle(1)}
        >
          Ecosystem
        </MenuItem>
        <MenuItem
          onClick={() => handleClick("flexibility", 2)}
          style={activeStyle(2)}
        >
          Flexibility
        </MenuItem>
        <MenuItem
          onClick={() => handleClick("care-and-assurance", 3)}
          style={activeStyle(3)}
        >
          Care &<br /> Assurance
        </MenuItem>
        <MenuItem
          onClick={() => handleClick("community-and-partnership", 4)}
          style={activeStyle(4)}
        >
          Community & Partnership
        </MenuItem>
        <MenuItem
          onClick={() => handleClick("flat-by-default", 5)}
          style={activeStyle(5)}
        >
          Flat by default
        </MenuItem>
      </NavigationBar>
    </NavigationBarContainer>
  )
}

export default CareerNavigationBar
