import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Chevron from "../../assets/svgs/chevron.svg"

const SalaryCounterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-inline: auto;
  flex-direction: column;
  gap: 2rem;

  margin-top: 3rem;
  margin-bottom: 3.25rem;

  max-width: 100%;

  @media (min-width: 40em) {
    gap: 2.4375rem;
    flex-direction: row;
    margin-top: 4.25rem;
    margin-bottom: 6.5rem;
  }
`

const SalaryCounterBox = styled.div`
  display: flex;
  width: 320px;
  height: 150px;
  max-width: calc(100% - 2.5rem);
  margin-inline: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;

  box-shadow: rgba(122, 121, 121, 0.2) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 4px 10px;
`

const SalaryCounterResultBox = styled(SalaryCounterBox)`
  background: var(--clr-primary);
  color: white;
`

const SalaryCounterHeader = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 1.5em;
  line-height: 39px;

  @media (min-width: 40em) {
    font-family: var(--ff-primary);
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.0025em;
  }

  .gatsby-image-wrapper {
    width: 46px;
    height: 38px;

    @media (max-width: 40em) {
      display: none;
    }
  }
`

const SalaryResultHeader = styled(SalaryCounterHeader)`
  .gatsby-image-wrapper {
    width: 48px;
    height: 43px;

    @media (max-width: 40em) {
      display: none;
    }
  }
`

const SalaryCounterInput = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;

  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 2.125em;
  line-height: 44px;
`

const Salary = styled.div`
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 3em;
  line-height: 44px;

  @media (min-width: 40em) {
  }
`

const ChevronLeft = styled.button`
  border: 0;
  background: 0;
  height: fit-content;
  width: fit-content;
  svg {
    height: 25px;
    width: 25px;
  }

  display: inline-block;
  &:hover {
    cursor: ${p => (!p.disabled ? "pointer" : undefined)};
  }
  &:disabled {
    filter: contrast(0.3);
  }
  transform: rotate(90deg);
`

const ChevronRight = styled.button`
  border: 0;
  background: 0;
  height: fit-content;
  width: fit-content;
  svg {
    height: 25px;
    width: 25px;
  }
  &:hover {
    cursor: ${p => (!p.disabled ? "pointer" : undefined)};
  }
  &:disabled {
    filter: contrast(0.3);
  }
  transform: rotate(-90deg);
`

const SalaryCounter = () => {
  const allocation = {
    min: 50,
    max: 140,
    start: 100,
    stepRange: 5,
  }

  const priceRange = {
    min: 80,
    max: 140,
    start: 100,
    stepRange: 5,
  }

  const getSalary = (hourlyPrice, allocationPercentage) => {
    return Math.round(hourlyPrice * 0.5 * 157.5 * (allocationPercentage / 100))
  }

  const { min, max, start, stepRange } = allocation

  const {
    min: priceMin,
    max: priceMax,
    start: priceStart,
    stepRange: priceStepRange,
  } = priceRange

  const [allocationPercentage, setAllocationPercentage] = React.useState(start)
  const [pricePerHour, setPricePerHour] = React.useState(priceStart)

  const changeValue = action => () => {
    switch (action) {
      case "decreasePrice":
        if (pricePerHour > priceMin) {
          setPricePerHour(pricePerHour - priceStepRange)
        }
        break
      case "increasePrice":
        if (pricePerHour < priceMax) {
          setPricePerHour(pricePerHour + priceStepRange)
        }
        break
      case "increaseAllocation":
        if (allocationPercentage < max) {
          setAllocationPercentage(allocationPercentage + stepRange)
        }
        break
      case "decreaseAllocation":
        if (allocationPercentage > min) {
          setAllocationPercentage(allocationPercentage - stepRange)
        }
        break
      default:
        break
    }
  }
  return (
    <SalaryCounterContainer>
      <SalaryCounterBox>
        <SalaryCounterHeader>
          <StaticImage src="../../images/career/arrows_icon.png" alt="" />
          Hourly rate
        </SalaryCounterHeader>
        <SalaryCounterInput>
          <ChevronLeft
            onClick={changeValue("decreasePrice")}
            disabled={pricePerHour <= priceMin}
            title="decrease price"
          >
            <Chevron />
          </ChevronLeft>
          {pricePerHour} €/h
          <ChevronRight
            onClick={changeValue("increasePrice")}
            disabled={pricePerHour >= priceMax}
            title="increase price"
          >
            <Chevron />
          </ChevronRight>
        </SalaryCounterInput>
      </SalaryCounterBox>
      <SalaryCounterBox>
        <SalaryCounterHeader>
          <StaticImage src="../../images/career/arrows_icon.png" alt="" />
          Utilisation
        </SalaryCounterHeader>
        <SalaryCounterInput>
          <ChevronLeft
            onClick={changeValue("decreaseAllocation")}
            disabled={allocationPercentage <= min}
            title="decrease allocation"
          >
            <Chevron />
          </ChevronLeft>
          {allocationPercentage} %{" "}
          <ChevronRight
            onClick={changeValue("increaseAllocation")}
            disabled={allocationPercentage >= max}
            title="increase allocation"
          >
            <Chevron />
          </ChevronRight>
        </SalaryCounterInput>
      </SalaryCounterBox>
      <SalaryCounterResultBox>
        <SalaryResultHeader>
          {" "}
          <StaticImage src="../../images/career/salary_icon.png" alt="" />
          Your salary
        </SalaryResultHeader>
        <Salary>{getSalary(pricePerHour, allocationPercentage)} €</Salary>
      </SalaryCounterResultBox>
    </SalaryCounterContainer>
  )
}

export default SalaryCounter
