import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import DownloadIcon from "../../../assets/svgs/download-icon-dark.svg"
import DownloadLink from "../downloadLink/DownloadLink"
import { Card, CardContent } from "./Card"
import { DownloadButton } from "./DownloadButton"
import { Layout } from "./Layout"
import { Paragraph } from "./Paragraph"
import { Title } from "./Title"
import styled from "styled-components"

const NoWrap = styled.span`
  white-space: nowrap;
`

export const RebaseDigest = ({ dark = false }) => {
  return (
    <Layout dark={dark}>
      <Card>
        <StaticImage
          src="../../../assets/images/generic_booklet.png"
          alt="Rebase Digest"
          style={{ aspectRatio: "1.05", maxWidth: "400px" }}
        />
        <CardContent>
          <Title>
            Rebase Digest <NoWrap>May - August 2024</NoWrap>
          </Title>
          <Paragraph>
            Would you like to know more about role-specific salaries, billable
            rates, project opportunities and employee satisfaction?
          </Paragraph>
          <DownloadLink formId="6c88ae34-27c4-4161-b621-2be25986c5d1">
            <div style={{ display: "flex", cursor: "pointer" }}>
              <DownloadIcon />
              <DownloadButton>Download</DownloadButton>
            </div>
          </DownloadLink>
        </CardContent>
      </Card>
    </Layout>
  )
}

RebaseDigest.propTypes = {
  dark: PropTypes.bool,
}
